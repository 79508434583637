import { Box, Flex, Circle } from '@chakra-ui/react';

function StepIndicator({ currentStep, steps, setStep, isValidStep1 }) {
  return (
    <Flex align="center" justify="center" my="4" w="100%">
      <Flex align="center" justify="space-between" w="50%">
        {steps.map((_, index) => (
          <Flex key={_} align="center" flex="1" justify="center" position="relative">
            {index < steps.length - 1 && (
              <Box position="absolute" height="1px" bg="gray.500" left="50%" right="-50%" />
            )}
            <Box cursor="pointer" onClick={() => isValidStep1 && setStep(index + 1)} zIndex="1">
              <Circle
                size="20px"
                bg={index < currentStep ? '#EBD0FF' : '#f7fafc'}
                borderColor={index <= currentStep ? 'black' : 'gray.500'}
                borderWidth="2px"
                borderStyle={index < currentStep ? 'dotted' : 'dotted'}
              />
            </Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export default StepIndicator;
