/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */

import React, { useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import * as amplitude from '@amplitude/analytics-browser';

import { Box, Flex, Heading, Text, useToast, useDisclosure } from '@chakra-ui/react';

import { useGetChallengesQuery } from '../Redux/features/challenges/challengesApiSlice';
import { useGetUserDataQuery } from '../Redux/features/auth/authApiSlice';

import LoadingChallenges from '../Components/LoadingChallenges/LoadingChallenges';
import ScrollToTop from '../Components/ScrollToTop/ScrollToTop';
import Header from '../Components/Header/Header';
import ChallengeItem from '../Components/ChallengeItem/ChallengeItem';
import FooterNav from '../Components/FooterNav/FooterNav';
import NoMissionItem from '../Components/NoMissionItem/NoMissionItem';
import NoMissionsAvailablesToParticipate from '../Components/NoMissionsAvailablesToParticipate/NoMissionsAvailablesToParticipate';
import TikTokMissionItem from '../Components/TikTokMissionItem/TikTokMissionItem';

import CreatorInfoModal from '../Components/CreatorInfoModal/CreatorInfoModal';
import { useGetParticipationsQuery } from '../Redux/features/participations/participationsApiSlice';

// Milisegundos que solicitamos los datos del usuario
const POLLING_INTERVAL = 30000;

export default function MissionsPage() {
  // Toast
  const toast = useToast();
  const showToast = useCallback(
    (message, type) => {
      toast({
        title: `${message}`,
        status: type,
        isClosable: true,
        duration: 2000,
      });
    },
    [toast],
  );

  // Cuando conecta el TikTok
  const [searchParams] = useSearchParams();
  const tikTokConnectedSuccess = searchParams.get('tikTokConnected');
  const connectionError = searchParams.get('error');

  // Modal conectar TikTok
  const {
    isOpen: isOpenConnectTikTokModal,
    onOpen: onOpenConnectTikTokModal,
    onClose: onCloseConnectTikTokModal,
  } = useDisclosure();

  // Detectamos si ha conectado el tiktok y mostramos confetti
  useEffect(() => {
    if (tikTokConnectedSuccess === 'true') {
      amplitude.track('tiktok_connected');

      const script = document.createElement('script');

      script.src = 'https://cdn.jsdelivr.net/npm/js-confetti@latest/dist/js-confetti.browser.js';
      script.async = false;

      document.body.appendChild(script);

      let JSConfetti = null;

      script.onload = () => {
        if (window.JSConfetti) {
          JSConfetti = window.JSConfetti;
          const jsConfetti = new JSConfetti();
          jsConfetti.addConfetti({
            emojis: ['💸', '💰'],
            emojiSize: 100,
            confettiNumber: 30,
          });
        }
      };

      showToast('TikTok conectado con éxito', 'success');
    } else if (tikTokConnectedSuccess === 'false') {
      onOpenConnectTikTokModal();
      if (connectionError === 'duplicated') {
        showToast('Ya tienes este TikTok conectado a otra cuenta de CRUWI.', 'error');
      } else if (connectionError === 'missing-permissions') {
        showToast('No nos has dado los permisos necesarios para conectarte con CRUWI :( ', 'error');
      } else if (connectionError === 'requirements') {
        showToast('No cumples con los requisitos mínimos', 'error');
      } else {
        showToast('TikTok no conectado. Deberás conectarlo para participar en los retos.', 'error');
      }
    }
  }, [tikTokConnectedSuccess, showToast, connectionError, onOpenConnectTikTokModal]);

  // Pedimos datos de los retos-misiones activos
  const {
    data: challenges,
    isLoading,
    isSuccess,
  } = useGetChallengesQuery({
    isMission: true,
    isActive: true,
  });

  // Pedimos datos del usuario
  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useGetUserDataQuery(null, {
    pollingInterval: POLLING_INTERVAL,
  });

  const { data: participations, isLoading: isLoadingParticipations } = useGetParticipationsQuery(
    null,
    {
      pollingInterval: POLLING_INTERVAL,
    },
  );

  // Mostramos algo mientras carga
  if (isLoading || isUserLoading || isLoadingParticipations) {
    return <LoadingChallenges />;
  }

  // Retos no reclamados, ni repetidos ni de retos no activos (no mostramos los claimed), sí mostramos los que están en revisión
  const shownChallenges = participations
    ?.filter((participation) => participation.challenge?.isMission)
    .filter((participation) => {
      if (participation.status === 'revision') return true;
      if (participation.status === 'claimed') return false;
      if (participation.repeated) return false;
      return !(!participation.challenge.isActive && participation.status !== 'approved');
    });

  // Comprobamos si tiene o no conectado el TikTok
  const hasTikTokConnected = user?.tikTokInfo.hasOwnProperty('openId');
  const hasTikTokSessionExpired = user?.tikTokInfo.outdated === true;

  // Comprobamos si el usuario puede participar en las misiones
  const userCanParticipateInMissions = user?.tikTokInfo?.followerCount >= 5000;

  // Obtenemos de qué marca (si viene de una) viene el usuario
  const brandReferral = user?.brandReferral?.toLowerCase();

  // Comprobamos si mostramos el banner para más info (edad, género, gustos)
  const shouldShowModal =
    Object.keys(user?.tikTokInfo || {}).length > 0 && user?.segmentation === undefined;

  // Filtramos de los retos, los que ya está apuntado y no los mostramos 2 veces..
  const filteredChallenges = challenges?.data.filter((challenge) => {
    const participation = participations.find(
      (searchedParticipation) => searchedParticipation.challengeId === challenge.id,
    );
    return participation === undefined || participation.repeated === true;
  });

  const newSortedChallengesByReferredByAndPriotity = filteredChallenges.slice().sort((a, b) => {
    // First criteria: brandReferral matching with brand.name
    if (
      a.brand.name.toLowerCase() === brandReferral &&
      b.brand.name.toLowerCase() !== brandReferral
    ) {
      return -1;
    }

    if (
      a.brand.name.toLowerCase() !== brandReferral &&
      b.brand.name.toLowerCase() === brandReferral
    ) {
      return 1;
    }

    // Second criteria: higher priority
    if (a.priority !== b.priority) {
      return b.priority - a.priority; // Greater priority first
    }

    // Third criteria: newer launchDate
    const launchDateA = new Date(a.launchDate);
    const launchDateB = new Date(b.launchDate);
    return launchDateB - launchDateA; // Newer first
  });

  return (
    <Box bg="#FFFFF2" minH="100dvh">
      <ScrollToTop />
      <Header user={user} />
      <Box pb="60px" mt={5} ml={5} mr={5}>
        {/* Mostrar si no tiene 5000 seguidores */}
        {userCanParticipateInMissions === false && (
          <Box
            maxWidth="700px"
            margin="0 auto"
            bg="#FF6961"
            borderRadius="16px"
            p={3}
            border="2px solid"
            mb={4}
            boxShadow="2px 2px 0px 0px #000"
          >
            <Text fontWeight="bold" color="black" fontSize="12px" lineHeight={1.3}>
              No eres apto para participar en misiones. Necesitas al menos 5.000 seguidores para
              poder participar.
            </Text>
          </Box>
        )}

        {/* Mis Misiones */}
        <Box mb={8} maxWidth="700px" margin="0 auto">
          <Flex justifyContent="space-between">
            <Heading fontSize="24px" fontFamily="DM Sans">
              Mis misiones
            </Heading>
          </Flex>
          <Text
            fontFamily="DM Sans"
            fontStyle="italic"
            opacity="0.8"
            lineHeight={1.2}
            fontSize="12px"
          >
            Misiones en las que participas
          </Text>

          {/* Listado de retos en los que participas */}
          <Box mt={5}>
            <Box mb={5}>
              {(!hasTikTokConnected || hasTikTokSessionExpired) && (
                <TikTokMissionItem
                  connectionError={connectionError}
                  openTikTokModal={onOpenConnectTikTokModal}
                  isOpenTikTokModal={isOpenConnectTikTokModal}
                  closeTikTokModal={onCloseConnectTikTokModal}
                  hasExpiredSession={hasTikTokSessionExpired}
                />
              )}
            </Box>
            {isUserSuccess &&
              shownChallenges.map((challenge) => (
                <ChallengeItem
                  isMission
                  status={challenge?.status}
                  challengeData={challenge.challenge}
                  key={challenge.id}
                  userData={user}
                  videoCreateTime={new Date(challenge?.mediaPost?.createdAt)}
                />
              ))}
            {isUserSuccess &&
              hasTikTokConnected &&
              (participations?.length === 0 || shownChallenges.length === 0) && <NoMissionItem />}
          </Box>
        </Box>

        {/* Misiones disponibles */}
        <Box maxWidth="700px" margin="0 auto">
          <Flex justifyContent="space-between">
            <Heading fontSize="24px" fontFamily="DM Sans">
              Misiones disponibles
            </Heading>
          </Flex>
          <Text
            fontFamily="DM Sans"
            fontStyle="italic"
            opacity="0.8"
            lineHeight={1.2}
            fontSize="12px"
          >
            Haz un TikTok que cumpla con la descripción de la misión para ganar recompensas
          </Text>

          {/* Listado de Misiones disponibles */}
          <Box mt={5}>
            {isSuccess &&
              newSortedChallengesByReferredByAndPriotity?.map((challenge) => (
                <ChallengeItem
                  challengeData={challenge}
                  key={challenge.id}
                  userData={user}
                  participations={participations}
                />
              ))}
            {isSuccess && newSortedChallengesByReferredByAndPriotity.length === 0 && (
              <NoMissionsAvailablesToParticipate />
            )}
          </Box>
        </Box>
      </Box>
      <CreatorInfoModal isOpen={shouldShowModal} />
      <FooterNav />
    </Box>
  );
}
