import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useMediaQuery,
  Box,
  ModalHeader,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
} from '@chakra-ui/react';

const faqs = [
  {
    question: '¿Qué es un reto?',
    answer:
      'Un reto es la forma en la que colaboras con una marca en CRUWI. Simplemente debes leer lo que pide la marca, apuntarte si te gusta y subir el vídeo a tu perfil de TikTok o Instagram.',
  },
  {
    question: '¿Qué es una misión?',
    answer:
      'Una misión es como un reto pero con la diferencia de que para poder participar necesitarás de un mínimo de 5000 seguidores. En una misión también se suele buscar un perfil más concreto (edad, sexo) y lo que se pide hacer suele ser más complejo. Por ello, las recompensas suelen ser mayores, tanto las fijas como las variables (€ por cada 1000 views).',
  },
  {
    question: '¿Qué son los créditos?',
    answer:
      'Un crédito es tu pase exclusivo para participar en los retos. Cuando participes en un reto, emplearás un crédito. Cuando subas el vídeo de ese reto, recuperarás el crédito aunque el vídeo sea rechazado (siempre y cuando tenga que ver con el reto).',
  },
  {
    question: '¿Cuándo pierdo un crédito?',
    answer:
      'Perderás un crédito para siempre cuando no subas el vídeo de un reto en el que has participado. Recuerda que, salvo que se indique lo contrario, tienes 72 horas desde que participas para subirlo.',
  },
  {
    question: '¿Cómo puedo participar en un reto?',
    answer:
      'Para poder participar en los retos que ves en la sección "Retos disponibles", deberás conectar primero tu TikTok o Instagram a nuestra app. Pulsa en conectar TikTok o Instagram en tu perfil o en la sección que aparece en "Mis retos". Una vez lo tengas conectado, elige el reto que más te guste y pulsa en "Participar". ¡Ya sólo tendrás que pasarnos el enlace al vídeo de tu TikTok o Reel con los hashtags y menciones que aparecen en el reto (si es que hay)!',
  },

  {
    question: 'He subido el vídeo, ¿ahora qué?',
    answer:
      'Una vez que hayas pulsado en "Participar" en uno de los retos y hayas subido el vídeo a tus redes según las indicaciones de la marca, deberás pulsar en el botón de "Enviar enlace" para poder enviarnos el enlace al vídeo y que se lo podamos mostrar a la marca. Iremos actualizando el estado de tu reto en el propio reto que te aparece en la sección de "Mis retos".',
  },
  {
    question: '¿Cuánto tiempo tengo que esperar?',
    answer:
      'Una vez hayas subido el vídeo a TikTok o Instagram y lo hayamos detectado correctamente, la marca dispone de hasta 72h para aprobar o denegar el vídeo. Algunas marcas aprueban o deniegan más rápido y otras más despacio pero no te preocupes, todos los vídeos serán revisados.',
  },
  {
    question: '¿Cuánto voy a ganar por mis views?',
    answer:
      'Cuando una marca ve tu vídeo y lo aprueba, ganarás el fijo correspondiente. La recompensa variable la ganarás a las 48h de subir el vídeo y tendremos en cuenta estas 48h para el importe de las recompensas. Recuerda que es 1€ por cada 1000 views durante las primeras 48h.',
  },
  {
    question: '¿Puedo borrar mi vídeo?',
    answer:
      'No y sí. Para poder retirar las recompensas de CRUWI debes mantener tus vídeos en tu feed un mínimo de 30 días desde que lo subiste. Una vez pasado ese tiempo, podrás borrarlo. ¡Te aconsejamos no hacerlo! Lo mejor es que si tienes un vídeo aprobado por una marca y estás contento con CRUWI, dejes el vídeo en tu feed. Algunas marcas miran los perfiles y les agradará ver con qué marcas has colaborado y qué vídeos has hecho :)',
  },
  {
    question: '¿Qué es el saldo disponible?',
    answer:
      'El saldo disponible es la cantidad de dinero que ya puedes solicitar para que te sea ingresada por PayPal el día de los pagos, que normalmente es el día 28 de cada mes.',
  },
  {
    question: '¿Qué son los saldos pendientes?',
    answer:
      'Los saldos pendientes son las recompensas que ya están calculadas y que has obtenido por completar tus retos. Se podrán añadir al saldo disponible (para retirar) una vez pasados 30 días, ya que así garantizamos a las marcas que los vídeos están publicados un mínimo de 30 días. Toda la información relativa a estas condiciones están en los términos y condiciones del creador.',
  },
  {
    question: '¿Qué es la retención del 20%?',
    answer:
      'Al retirar el saldo disponible a tu cuenta de PayPal se aplicará una retención del 20% que se compone de dos cosas: un 15% en concepto de retenciones de IRPF y, por otro lado, un 5% en concepto de gastos de gestión de la plataforma.',
  },
];

export default function HelpModalChallenges({ isOpen, onClose }) {
  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  return (
    <Modal
      closeOnOverlayClick
      size={isLargerThan768 ? 'xl' : 'full'}
      isOpen={isOpen}
      scrollBehavior="inside"
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent borderRadius={isLargerThan768 ? '24px' : '0px'}>
        <ModalHeader
          fontFamily="DM Sans"
          borderRadius={isLargerThan768 ? '24px 24px 0px 0px' : '0px'}
          bg="#FFFFF2"
        >
          Ayuda para creadores
        </ModalHeader>
        <ModalCloseButton
          bg="#FFFFF2"
          borderRadius="50%"
          m={1}
          boxShadow="1px 1px 0px 0px #000"
          border="2px solid black"
        />
        <ModalBody
          borderRadius={isLargerThan768 ? '0px 0px 24px 24px' : '0px'}
          fontFamily="DM Sans"
          p={0}
          bg="#FFFFF2"
        >
          <Box p={5}>
            <Accordion allowToggle>
              {faqs.map((faq) => (
                <AccordionItem
                  mb={4}
                  key={faq.question}
                  bg="white"
                  border="2px solid black"
                  borderRadius="16px"
                  boxShadow="2px 2px 0px 0px #000"
                >
                  <AccordionButton borderRadius="24px" bg="#fffff2" _hover={{ bg: '#fffff2' }}>
                    <Box pt={1} pb={1} fontWeight="bold" flex="1" textAlign="left">
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
