import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
  Flex,
  Heading,
} from '@chakra-ui/react';

import { FiX } from 'react-icons/fi';
import ChallengeItem from '../ChallengeItem/ChallengeItem';
import LoadingChallenges from '../LoadingChallenges/LoadingChallenges';
import NoChallengesCompleted from '../NoChallengesCompleted/NoChallengesCompleted';
import { useGetUserDataQuery } from '../../Redux/features/auth/authApiSlice';
import { useGetParticipationsQuery } from '../../Redux/features/participations/participationsApiSlice';

export default function HistoryDrawer({ isOpenHistoryDrawer, onCloseHistoryDrawer }) {
  const { data: user } = useGetUserDataQuery();
  const { data: participations, isLoadingParticipations } = useGetParticipationsQuery();

  // Mostramos solo los retos completados
  const shownParticipations =
    participations?.filter((participation) => participation.status === 'claimed') ?? [];

  // Mostramos los últimos retos completados los primeros
  const orderedData = shownParticipations?.slice().sort((a, b) => {
    const dateA = new Date(a.challenge?.createdAt);
    const dateB = new Date(b.challenge?.createdAt);
    return dateB - dateA;
  });

  // Mostramos algo mientras carga
  if (isLoadingParticipations) {
    return <LoadingChallenges />;
  }

  return (
    <Drawer isOpen={isOpenHistoryDrawer} placement="right" onClose={onCloseHistoryDrawer} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader
          bg="#FFFFF2"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="55px"
          borderBottomWidth="1px"
        >
          <Text>Historial</Text>
          <Box>
            <FiX cursor="pointer" size="20px" onClick={onCloseHistoryDrawer} />
          </Box>
        </DrawerHeader>

        <DrawerBody bg="#FFFFF2" padding={5}>
          {/* Aquí va el contenido de los retos completados */}
          <Box mb={8} maxWidth="700px" margin="0 auto">
            <Flex justifyContent="space-between">
              <Heading fontSize="24px" fontFamily="DM Sans">
                Retos completados
              </Heading>
            </Flex>
            <Text
              fontFamily="DM Sans"
              fontStyle="italic"
              opacity="0.8"
              lineHeight={1.2}
              fontSize="12px"
            >
              Retos que has completado
            </Text>

            {/* Listado de retos en los que participas */}
            <Box mt={5}>
              {participations &&
                orderedData.map((participation) => (
                  <ChallengeItem
                    status={participation?.status}
                    challengeData={participation.challenge}
                    key={participation.id}
                    userData={user}
                    videoCreateTime={new Date(participation?.mediaPost?.createdAt)}
                    participations={participations}
                  />
                ))}
              {participations && shownParticipations.length === 0 && <NoChallengesCompleted />}
            </Box>
          </Box>
        </DrawerBody>

        <DrawerFooter bg="#FFFFF2" padding={2} borderTopWidth="1px">
          <Button
            border="2px solid black"
            boxShadow="1px 1px 0px 0px #000"
            borderRadius="16px"
            bg="#FFFFF2"
            color="black"
            size="sm"
            onClick={onCloseHistoryDrawer}
          >
            Cerrar historial
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
