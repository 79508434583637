/* eslint-disable no-console */
import { Flex, Box, Text } from '@chakra-ui/react';
import RewardIcon from '../../Assets/Images/basil_present-solid.svg';

function Condition({ data }) {
  const { reward, text } = data;
  return (
    <Box mb={2}>
      <Flex>
        <Box>
          <Box
            border="1px solid black"
            boxShadow="0.5px 0.5px 0px 0px #000"
            width="18px"
            height="18px"
            borderRadius="50%"
            backgroundRepeat="no-repeat"
            backgroundSize="13px"
            backgroundPosition="center"
            backgroundColor="#ddded1"
            backgroundImage={`url(${RewardIcon})`}
            mr={2}
          />
        </Box>
        <Text fontSize="12px">
          Ganarás <strong>{reward}</strong> {text}
        </Text>
      </Flex>
    </Box>
  );
}

export default function LinkItemRewards({ challenge }) {
  const {
    affiliation: { conditions },
  } = challenge;
  return (
    <Box maxW="400px" margin="20px auto 0px auto">
      <Text fontWeight="bold" fontSize="16px" textAlign="center">
        ¿Cuándo ganarás recompensas?
      </Text>
      <Flex mt={2} flexDir="column">
        {conditions && conditions.map((c) => <Condition key={c.id} data={c} />)}
      </Flex>
    </Box>
  );
}
