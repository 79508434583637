import {
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

export default function DisabledAccountModal({ isOpen, onClose }) {
  const modalContent = (
    <Box fontFamily="DM Sans">
      <Text fontWeight="bold" fontSize="20px" mb="16px">
        Cuenta inhabilitada
      </Text>
      <Text fontSize="14px" lineHeight={1.3} mb="16px">
        Tu perfil ha sido inhabilitado para participar en los retos por alguno de los siguientes
        motivos:
      </Text>

      <UnorderedList mt={2} mb={5} fontSize="14px">
        <ListItem mb={2}>
          Has usado (o existe sospecha) mecanismos no permitidos para alterar las métricas de alguno
          de tus vídeos.
        </ListItem>
        <ListItem mb={2}>Las marcas han puntuado con un 0 tus vídeos.</ListItem>
        <ListItem>
          Tus contenidos no cumplen con los requisitos mínimos que exige una marca o CRUWI.
        </ListItem>
      </UnorderedList>

      <Text fontSize="14px" my={4}>
        Si crees que se trata de un error, escríbenos a <u>help@cruwi.com</u>
      </Text>

      <Box>
        <Button
          onClick={onClose}
          mt={2}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
        >
          Entendido
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent border="2px solid black" borderRadius="24px" m={5}>
        <ModalCloseButton bg="#fff" borderRadius="50%" m={1} border="1px solid" />
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={5}>
          {modalContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
